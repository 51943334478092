.search-input-field {
  @extend .form-control, .form-control-sm, .border-2;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% + 2px));
    right: 1rem;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    background: transparent url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url%28%23clip0_137_2221%29'%3e%3cpath d='M7.04606 0C3.16097 0 0 3.16097 0 7.04606C0 10.9314 3.16097 14.0921 7.04606 14.0921C10.9314 14.0921 14.0921 10.9314 14.0921 7.04606C14.0921 3.16097 10.9314 0 7.04606 0ZM7.04606 12.7913C3.87816 12.7913 1.30081 10.214 1.30081 7.04609C1.30081 3.87819 3.87816 1.30081 7.04606 1.30081C10.214 1.30081 12.7913 3.87816 12.7913 7.04606C12.7913 10.214 10.214 12.7913 7.04606 12.7913Z' fill='%23767676'/%3e%3cpath d='M15.8095 14.8897L12.0805 11.1607C11.8264 10.9066 11.4149 10.9066 11.1608 11.1607C10.9067 11.4146 10.9067 11.8265 11.1608 12.0804L14.8898 15.8094C15.0168 15.9364 15.1831 16 15.3496 16C15.5159 16 15.6824 15.9364 15.8095 15.8094C16.0636 15.5555 16.0636 15.1436 15.8095 14.8897Z' fill='%23767676'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_137_2221'%3e%3crect width='16' height='16' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e") 50%/1rem auto no-repeat;
  }
}

.input-answer {
  text-wrap: wrap;
}