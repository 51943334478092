$theme-colors: (
        'primary': #c32929,
        'success': #68cb92,
        'dark': #1b1b1b,
        'muted': #9ea5ab,
        'secondary': #e4e4e4,
        'danger': #ed4052,
        'warning': #fc9d79,
        'white': #ffffff,
        'secondary-light':#faf9f8
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';


:root {
  --primary: #c32929;
}