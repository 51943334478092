.form-select {
  border: none;
  padding-right: 1.5rem;
  cursor: pointer;
  padding-left: 0;
  text-transform: uppercase;
  font-weight: 500;
  @extend .text-uppercase;

  &:focus {
    box-shadow: none;
  }
}