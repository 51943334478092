@import './components/variables';
@import './components/images';
@import './components/typography';
@import './components/icons';
@import './components/margins';
@import './components/dimensions';
@import './components/forms';
@import './components/animations';
@import './components/tables';
@import './components/borders';
@import './components/buttons';
@import './components/pagination';
@import './components/input';
@import './components/select';
@import './components/googlemap';
@import './components/accordion';
@import './components/dropdown';
@import './components/positions';
@import './components/progressbar';

.row {
  width: 100%;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.layout-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

.w-fit {
  width: fit-content;
}

.default-radius {
  border-radius: 7px;
}

.top-100px {
  top: 100px;
}

.loading-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #ffffffc7;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

hover-effect {
  &:hover {
    opacity: .80;
  }
}

.card-border-radius {
  border-radius: 7px;
}

:root {
  --swiper-theme-color: gray !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}