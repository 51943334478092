.btn {
  @extend .text-white, .rounded-0;
}

.email-submit {
  color: $dark;
}


.product-card {

  .add-to-cart-button {
    position: absolute;
    bottom: -20px;
    left: 10px;
    right: 10px;
    transition: 0.1s ease-in;
    visibility: hidden;
    border: none;
    background-color: $white;
    padding: 10px;
    text-transform: uppercase;

    &:hover {
      filter: brightness(.95);
    }

  }

  &:hover {
    .add-to-cart-button {
      bottom: 10px;
      visibility: visible;
    }
  }
}

.cart-btn {
  background-color: transparent;
  border: none;
  padding: 10px 20px;

  &:disabled {
    cursor: not-allowed;
  }
}

.product-button {
  position: absolute;
  top: 50%;
  z-index: 1;
  border: none;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #dad9d9;
  }
}
