.position-absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-right-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1;
}

.top-60 {
    top: 60%;
}

.title-position {
    position: absolute;
    top: 50%;
}