body {
  font-family: "Jost", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  @extend .mb-0;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}