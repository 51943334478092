.min-h-50vh {
  min-height: 50vh;
}

.w-120px {
  width: 120px;
}

.h-200px {
  height: 200px;
}

.max-w-400 {
  max-width: 400px;
}

.wh-25px {
  width: 25px;
  height: 25px;
}

.w-15 {
  width: 15%;
}

.h-300px {
  height: 300px;
}

.h-80vh {
  height: 80vh;
}

.h-60vh {
  height: 60vh;
}

.h-400px {
  height: 400px;
}

.h-50vh {
  height: 50vh;
}

.wh-100px {
  width: 100px;
  height: 100px;
}

@media (max-width: 480px) {
  .h-300px,
  .h-400px {
    height: 250px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .h-400px {
    height: 300px;
  }
}
