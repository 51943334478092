.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $dark;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
}

.text-primary {
  .hover-underline-animation {
    &::after {
      background-color: $danger;
    }
  }

  &.hover-underline-animation {
    &::after {
      background-color: $danger;
    }
  }
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-animation {
  transition: all 0.15s ease-in-out;
}

.dashboard-info-text {
  position: absolute;
  top: 50%;
  animation: slidein 1s;
}

.hamburger {
  display: none;
}

.hamburger div {
  background: #f64c4c !important;
  height: 3px;
  margin: 5px;
  transition: all 0.3s ease;
  width: 30px;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 768px) {
  .hamburger {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    transition: all 0.7s ease;
    z-index: 2;
  }
  .dashboard-info-text {
    //top: 20%;
    top: 30%;
  }
  @keyframes slidein {
    0% {
      top: 100%;
      opacity: 0;
    }

    50% {
      top: 50%;
      opacity: 0;
    }

    100% {
      top: 20%;
      opacity: 1;
    }
  }
}

@keyframes slidein {
  0% {
    top: 100%;
    opacity: 0;
  }

  50% {
    top: 75%;
    opacity: 0;
  }

  100% {
    top: 50%;
    opacity: 1;
  }
}
