.header-logo {
  width: 50px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.product-order-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.product-img-big {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.title-bg-img {
  object-fit: cover;
  margin-top: 50px;
  height: 25vh;
}

.product-img-sm {
  @extend .product-order-img;
  height: 100px;
}

.dashboard-landing-img {
  filter: blur(2px);
}
