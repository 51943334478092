.accordion-button {
    padding-left: 0px;
    padding-right: 0px;
    font-weight: bold;

    &:focus {
        box-shadow: none;
    }
}

.accordion-button:not(.collapsed) {
    color: $dark;
    background-color: #ffffff;
    box-shadow: none;
}

.accordion-body {
    padding-left: 0px;
    padding-right: 0px;
}

.accordion-item {

    outline: none;

}